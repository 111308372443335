<template>
  <div class="wrap" :class="lang">
    <Header/>
    <div class="app-content">
      <router-view />
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/layout/components/Header.vue'
import Footer from '@/layout/components/Footer.vue'

export default {
  name: 'Layout',
  components: {
    Header,
    Footer
  },
  computed: {
    key() {
      return this.$route.path
    },
    lang() {
      return this.$i18n.locale
    },
  }
}
</script>

