const en = {
  login: {
    title: 'Login',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    forgetPassword: 'Forget Password',
    tip1: 'Logging in means you have accepted',
    tip2: 'our terms and conditions',
    passwordRecovery: 'Password Recovery',
    emailPlaceholder: 'Please enter your registered email',
    next: 'Next',
    backLogin: 'Return to Login',
    firstLogin: 'First time login',
    firstLoginTip: 'You are required to set a new password when you first login to the platform',
    passwordTip: 'Please use a combination of numbers, upper-and-lower case letters with a minimal of 7 digits.',
    emailError: 'Email cannot be empty',
    emailError2: 'Please enter the correct email',
    passwordError: 'Password cannot be empty',
    passwordError2: 'Please enter your password',
    verificationCode: 'Verification Code',
    getVerificationCode: 'Get verification code',
    verificationCodeError: 'Verification code cannot be empty',
    codeSent: 'Verification code has been sent',
    Resend: 'Resend',
    submit: 'Confirm and login',
  },
  nav: {
    fundOverview: 'Fund Overview',
    opportunities: 'Opportunities',
    insights: 'ZWC Insights',
    consumerInsights: 'ZWFM Insights',
    events: 'ZWC Events',
  },
  fund: {
    fundSize: 'Fund Size',
    ClosingDate: 'Closing Date',
    FundTerm: 'Fund Term',
    DeployedCapital: 'Deployed Capital',
    TotalDistribution: 'Total Distribution',
    portfoliosNumber: 'No. of portfolios',
    registeredLocation: 'Registered Location',
    lpReport: 'LP Report',
    FundPortfolio: 'Fund Portfolio Details',
  },
  project: {
    investmentDate: 'Investment Date',
    investmentAmount: 'investment amount',
    investmentRound: 'Latest Round',
    entryRound: 'Entry Stage',
    Foundedin: 'Founded in',
    PortfolioOverview: 'Portfolio Overview',
    RelatedSharing: 'CEO Sharing',
    FoundingTeam: 'Team',
    LatestValuation: 'Latest valuation',
    LatestUpdates: 'Latest Updates',
    InvestmentReports: 'Investment Reports',
    FairValue: 'Fair Value',
    Metrics: 'Metrics',
  },
  status: {
    Ended: 'Ended',
    Scheduled: 'Scheduled',
    Live: 'Live',
  },
  events: {
    Replay: 'Replay',
    RSVP: 'RSVP',
    EditRSVP: 'Edit RSVP',
    JoinNow: 'Join Now',
    StartTime: 'Date and time',
    Status: 'Status',
    Topic: 'Topic',
    Success: 'Reservation Success',
    Url: 'Link',
    Qrcode: 'QR code',
    Detail: 'View Detail'
  },
  Logout: 'Log out',
  EditProfile: 'Edit Profile',
  Company: 'Company',
  Avatar: 'Avatar',
  Email: 'Email',
  Confirm: 'Confirm',
  Cancel: 'Cancel',
  Save: 'Save',
  remark: 'Remark',
  moreNews: 'More News',
  PortfolioNews: 'Portfolio News',
  BeijingTime: 'Beijing Time',
  siteName: 'ZWC Partners',
  home: 'Home',
  uploadTypeError: 'Incorrect file format!',
  uploadSizeError: 'Image size should not exceed 2MB!',
  fileDownload: 'Report Download',
  FundOverview: 'Fund Overview',
  LiveOpportunities: 'Live Opportunities',
  PortfolioOpportunities: 'Portfolio Opportunities',
  Number: 'No.',
  Code: 'Code',
  Description: 'Description',
  Industry: 'Industry',
  Stage: 'Stage',
  Action: 'Action',
  Early: 'Early',
  Growth: 'Growth',
  IPO: 'IPO',
  more: {
    insights: 'More ZWC Insights',
    consumerInsights: 'More ZWFM Insights',
    events: 'More ZWC Events',
  },
  download: 'Download',
  iosDownload: 'iOS App Download',
  androidDownload: 'Android app download',
  consumerDesc: '',
  consumerDesc2: '',
  latestInsights: 'New Insights',
  MarkedtoMarket: 'Marked to Market',
  PostMoneyValuation: 'Post Money Valuation',
  ResearchTeam: 'Research Team',
  LatestProgress: 'Latest Progress',
  ViewReport: 'View Report',
  DeadLine: 'As of',
  SelectedProjects: '(Some selected projects)',
  entryRound: 'Entry Round',
  LatestValuation: 'Latest Valuation',
  ProjectName: 'Name',
  Date: 'Date',
  investmentCurrency: 'Investment Currency',
}

export default en
