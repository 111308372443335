// 引入axios实例一
import { get, post, put, deletes } from "./request"

let api = process.env.VUE_APP_API

//post 通用请求
export const $post = (url, data) => {
  return post(api + url, data)
}

//get 通用请求
export const $get = (url, data) => {
  return get(api + url, data)
}

// 上传单个文件
export const upload = data => {
  return post(api + "/foundation/api/file", data, 'multipart/form-data')
}

// 登录
export const login = data => {
  return post(api + "/foundation/api/usercenter/login", data)
}

// 首次登录重置密码
export const fristLoginResetPasswd = data => {
  return put(api + "/foundation/api/usercenter/firstLoginResetPasswd", data)
}

// 获取用户基础信息
export const getUserInfo = () => {
  return get(api + "/foundation/api/usercenter")
}

// 更新用户基本信息
export const updateUserBaseInfo = data => {
  return put(api + "/foundation/api/usercenter/updateUserBaseInfo", data)
}

// 邮箱找回密码
export const resetPasswordWithEmail = data => {
  return put(api + "/foundation/api/usercenter/resetPasswordWithEmail", data)
}

// 通过邮箱获取验证码
export const getValidCodeByEmail = (email) => {
  return get(`${api}/foundation/api/usercenter/getValidCodeByEmail/${email}`)
}

// 用户已购基金列表及专项管理
export const purchasedFundAndProjectList = () => {
  return get(`${api}/foundation/api/fund/purchasedFundAndProjectList`)
}

// 单个基金信息
export const getFund = (id) => {
  return get(`${api}/foundation/api/fund/${id}`)
}

// 基金已投项目
export const getInvestedProjects = (id) => {
  return get(`${api}/foundation/api/fund/projectMapping/${id}`)
}

// 单个项目信息
export const getProject = (id, data) => {
  return post(`${api}/foundation/api/project/${id}`, data)
}

// 单个项目获取新闻分页
export const getNewsList = (projectId, data) => {
  return post(`${api}/foundation/api/project/${projectId}/news/page`, data)
}

// 获取单个新闻信息
export const getNews = (id) => {
  return get(`${api}/foundation/api/article/new/${id}`)
}

// 获取置顶活动信息
export const topActivity = () => {
  return get(`${api}/foundation/api/activity/topActivity`)
}

// 获取活动列表
export const activityList = data => {
  return post(`${api}/foundation/api/activity/activityList`, data)
}

// 获取活动列表分页
export const activityPage = (data, query) => {
  return post(`${api}/foundation/api/activity/activityPage?${query}`, data)
}

// 获取分类名称
export const categoryList = data => {
  return post(`${api}/foundation/api/article/categoryList`, data)
}

// 获取单个活动信息
export const getActivity = (id) => {
  return get(`${api}/foundation/api/activity/${id}`)
}

// 预约活动
export const orderEvent = (id) => {
  return put(`${api}/foundation/api/activity/order/${id}`)
}

// 文章新闻分页
export const getArticles = (data, query) => {
  return post(`${api}/foundation/api/article/page?${query}`, data)
}

// 文章新闻列表
export const getArticlesAll = data => {
  return post(`${api}/foundation/api/article/list`, data)
}

// 获取单个投资机会的文件列表
export const getFiles = (id) => {
  return get(`${api}/foundation/api/investChance/files/${id}`)
}

// 投资机会-列表
export const getInvestChance = data => {
  return post(`${api}/foundation/api/investChance/list`, data)
}


export default {
  $get,
  $post,
  upload,
  login,
  fristLoginResetPasswd,
  getUserInfo,
  updateUserBaseInfo,
  resetPasswordWithEmail,
  getValidCodeByEmail,
  purchasedFundAndProjectList,
  getFund,
  getInvestedProjects,
  getProject,
  getNewsList,
  getNews,
  topActivity,
  activityList,
  activityPage,
  categoryList,
  getActivity,
  orderEvent,
  getArticles,
  getArticlesAll,
  getFiles,
  getInvestChance
}
