import Vue from 'vue'
import Vuex from 'vuex'
import router from "@/router";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    // 获取用户信息
    logout({ state }) {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      router.push('/login');
    },
  },
  modules: {
  }
})
