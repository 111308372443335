import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: { isPublic: true, title: 'login.title' }
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPassword.vue'),
    meta: { isPublic: true, title: 'login.passwordRecovery' }
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: () => import('@/views/h5/Detail.vue'),
    meta: { isPublic: true }
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('@/views/h5/Agreement.vue'),
    meta: { isPublic: true }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/h5/Privacy.vue'),
    meta: { isPublic: true }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home'),
        meta: { title: 'home' }
      },
      {
        path: '/fund/:id',
        name: 'Fund',
        component: () => import('@/views/Fund'),
        meta: { }
      },
      {
        path: '/projects/:id',
        name: 'Projects',
        component: () => import('@/views/Projects'),
        meta: { title: 'fund.FundPortfolio' }
      },
      {
        path: '/news/:id',
        name: 'News',
        component: () => import('@/views/news/List'),
        meta: { title: 'moreNews' }
      },
      {
        path: '/news-detail/:id',
        name: 'NewsDetail',
        component: () => import('@/views/news/Detail'),
        meta: { }
      },
      {
        path: '/project-detail/:id',
        name: 'ProjectDetail',
        component: () => import('@/views/ProjectDetail'),
        meta: { }
      },
      {
        path: '/project/:id',
        name: 'Project',
        component: () => import('@/views/Project'),
        meta: { }
      },
      {
        path: '/events',
        name: 'Events',
        component: () => import('@/views/event/List'),
        meta: { title: 'nav.events' }
      },
      {
        path: '/event/:id',
        name: 'EventDetail',
        component: () => import('@/views/event/Detail'),
        meta: { }
      },
      {
        path: '/insights',
        name: 'Insights',
        component: () => import('@/views/insights/List'),
        meta: { title: 'nav.insights' }
      },
      {
        path: '/consumer',
        name: 'ConsumerInsights',
        component: () => import('@/views/consumer/List'),
        meta: { title: 'nav.consumerInsights' }
      },
      {
        path: '/opportunities',
        name: 'Opportunities',
        component: () => import('@/views/Opportunities'),
        meta: { title: 'nav.opportunities' }
      },
      {
        path: '/pdf',
        name: 'Pdf',
        component: () => import('@/views/Pdf'),
        meta: { }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
