<template>
  <div>
    <div class="header">
      <div class="container">
        <div class="header-top">
          <div class="logo">
            <router-link to="/home"><img src="../../assets/logo.png" alt="众为资本" /></router-link>
          </div>
          <el-popover placement="bottom" width="200" trigger="hover">
            <div class="user-popover">
              <div class="user-avatar">
                <el-avatar :size="52" :src="userInfo.avatar"><img src="../../assets/avatar.svg" /></el-avatar>
              </div>
              <div class="user-name">{{ userInfo.nickname }}</div>
              <el-row type="flex" justify="space-between">
                <el-button type="primary" plain size="mini" @click="logout">{{ $t('Logout') }}</el-button>
                <el-button type="primary" size="mini" @click="drawer = true">{{ $t('EditProfile') }}</el-button>
              </el-row>
            </div>
            <div class="user" slot="reference">
              <el-avatar :size="18" class="avatar" :src="userInfo.avatar"><img src="../../assets/avatar.svg" /></el-avatar>{{ userInfo.nickname }}
            </div>
          </el-popover>
        </div>
        <div class="header-content">
          <ul class="nav-menu">
            <li>
              <router-link to="/home">{{ $t('project.PortfolioOverview') }}</router-link>
            </li>
            <li v-if="userInfo && userInfo.userTypeId != 1">
              <router-link to="/opportunities">{{ $t('nav.opportunities') }}</router-link>
            </li>
            <li>
              <router-link to="/insights">{{ $t('nav.insights') }}</router-link>
            </li>
            <li>
              <router-link to="/consumer">{{ $t('nav.consumerInsights') }}</router-link>
            </li>
            <li>
              <router-link to="/events">{{ $t('nav.events') }}</router-link>
            </li>
          </ul>
          <ChangeLanguage />
        </div>
      </div>
    </div>
    <el-drawer :title="$t('EditProfile')" :size="drawerSize" :visible.sync="drawer" :direction="direction" @open="initForm">
      <div class="user-form">
        <el-form ref="form" :model="form">
          <div class="form-avatar">
            <el-upload class="avatar-uploader" action="" :auto-upload="false" accept="image/jpg,image/jpeg,image/png" :show-file-list="false" :on-change="handleAvatarChange">
              <img :src="form.avatar" v-if="form.avatar && form.avatar != ''" />
              <el-avatar :size="88" :src="userInfo.avatar" v-else><img src="../../assets/avatar.svg" /></el-avatar>
            </el-upload>
          </div>
          <el-form-item :label="$t('login.username')" prop="nickname" :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.nickname" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Email')">
            <el-input v-model="userInfo.email" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Company')">
            <el-input v-model="form.company" :maxlength="30"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn-block" @click="submitForm('form')">{{ $t('Save') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import api from '@/api'
import ChangeLanguage from '@/components/ChangeLanguage.vue'

export default {
  name: 'Header',
  components: {
    ChangeLanguage,
  },
  data() {
    return {
      drawerSize: 490,
      drawer: false,
      direction: 'rtl',
      form: {
        nickname: '',
        avatar: '',
        company: '',
      },
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : {},
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale == 'en' ? 'English' : '简体中文'
    },
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },

    initForm() {
      this.form.nickname = this.userInfo.nickname
      this.form.avatar = this.userInfo.avatar
      this.form.company = this.userInfo.company
      this.$refs['form'].resetFields()
    },

    // 获取用户信息
    getUserInfo() {
      api.getUserInfo().then((res) => {
        console.log(res)
        this.userInfo = res.data
        localStorage.setItem('userInfo', JSON.stringify(res.data))
      })
    },

    handleAvatarChange(file) {
      const success = this.beforeAvatarUpload(file.raw)
      if (success) {
        const formData = new FormData()
        formData.append('file', file.raw)
        api.upload(formData).then((res) => {
          console.log(res)
          this.form.avatar = res.data.url
          console.log(this.form.avatar)
        })
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = /^image\/(jpeg|png|jpg)$/.test(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error(this.$t('uploadTypeError'))
      }
      if (!isLt2M) {
        this.$message.error(this.$t('uploadSizeError'))
      }
      return isJPG && isLt2M
    },

    // 更新用户基本信息
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api.updateUserBaseInfo(this.form).then((res) => {
            this.drawer = false
            this.getUserInfo()
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
