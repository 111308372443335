<template>
  <div class="header-right">
    <!-- <div class="language">
      <el-dropdown @command="toggleLang">
        <span class="el-dropdown-link">
          {{lang}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh">简体中文</el-dropdown-item>
          <el-dropdown-item command="en">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->
    
    <!-- <el-popover
      width="262"
      trigger="click">
      <ul class="app-qrcode">
        <li>
          <vue-qr :text="ios"></vue-qr>
          <p>{{ $t('iosDownload') }}</p>
        </li>
        <li>
          <vue-qr :text="android"></vue-qr>
          <p>{{ $t('androidDownload') }}</p>
        </li>
      </ul>
      <div class="app-download" slot="reference">下载APP</div>
    </el-popover> -->
  </div>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: 'ChangeLanguage',
  components: {
    VueQr,
  },
  data() {
    return {
      ios: process.env.VUE_APP_IOS_DOWNLOAD,
      android: process.env.VUE_APP_ANDROID_DOWNLOAD,
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale == 'en' ? 'English' : '简体中文'
    },
  },
  methods: {
    //切换语言
    toggleLang(lang) {
      if(lang == 'zh') {
        //中文
        localStorage.setItem('locale', 'zh')
        this.$i18n.locale = localStorage.getItem('locale')
      } else if(lang == 'en') {
        //英文
        localStorage.setItem('locale', 'en')
        this.$i18n.locale = localStorage.getItem('locale')
      }
      location.reload()
    }
  }
}
</script>
