import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import '@/styles/element-variables.scss'

import '@/styles/index.scss' // global css

import i18n from './i18n/i18n'

import { getPageTitle } from './utils/pageTitle'

Vue.use(ElementUI)

Vue.config.productionTip = false

// 切换接口返回的中英文字段
Vue.prototype.getText = function (item,field) {
  const enField = 'en' + field.charAt(0).toUpperCase()+field.slice(1)
  return i18n.locale == 'en' ? item[enField] : item[field]
}

Vue.prototype.toPdf = function (fileUrl) {
  let routeData = this.$router.resolve({
    path: `/pdf?fileUrl=${fileUrl}`
  })
  window.open(routeData.href, '_blank')
}

Vue.prototype.toNewsDetail = function (item) {
  if (item.resourceType == 'LINK') {
    if (item.link) {
      window.open(item.link, '_blank')
    }
  } else {
    router.push(`/news-detail/${item.id}`)
  }
}

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title
  if (title) {
    document.title = getPageTitle(i18n, title)
  }
  if (!to.meta.isPublic) {
    const token = localStorage.getItem("token")
    if (!token) {
      next({ path: '/login' })
    }
    next()
  }
  next()
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
