const cn = {
  login: {
    title: '登录',
    username: '用户名',
    email: '邮箱',
    password: '密码',
    forgetPassword: '密码找回',
    tip1: '登录及代表您已同意',
    tip2: '《服务协议和隐私政策》',
    passwordRecovery: '密码找回',
    emailPlaceholder: '请输入邮箱',
    next: '下一步',
    backLogin: '返回登录',
    firstLogin: '首次登录',
    firstLoginTip: '首次登录需要您重置密码',
    passwordTip: '请输入数字，大小写字母，最少输入7位',
    emailError: '邮箱不可为空',
    emailError2: '请输入正确邮箱',
    passwordError: '密码不可为空',
    passwordError2: '请输入密码',
    verificationCode: '验证码',
    getVerificationCode: '获取验证码',
    verificationCodeError: '验证码不能为空',
    codeSent: '验证码已发送',
    Resend: '重新发送',
    submit: '确认并登录',
  },
  nav: {
    fundOverview: '基金概览',
    opportunities: '投资机会',
    insights: '众为观点',
    consumerInsights: '研究院洞察',
    events: '众为活动',
  },
  fund: {
    fundSize: '基金规模',
    ClosingDate: '成立日期',
    FundTerm: '基金年限',
    DeployedCapital: '累计出资',
    TotalDistribution: '累计分发',
    portfoliosNumber: '项目数量',
    registeredLocation: '注册地址',
    lpReport: 'LP报告',
    FundPortfolio: '已投项目详情',
  },
  project: {
    investmentDate: '投资时间',
    investmentAmount: '投资金额',
    investmentRound: '最新轮次',
    entryRound: '进入阶段',
    Foundedin: '创办于',
    PortfolioOverview: '项目概况',
    RelatedSharing: '创始人分享',
    FoundingTeam: '创始人团队介绍',
    LatestValuation: '公司最新估值',
    LatestUpdates: '最新情况',
    InvestmentReports: '项目报告',
    FairValue: '公允价值',
    Metrics: '项目数据',
  },
  status: {
    Ended: '已结束',
    Scheduled: '未开始',
    Live: '进行中',
  },
  events: {
    Replay: '查看回播',
    RSVP: '活动预约',
    EditRSVP: '已预约 查看',
    JoinNow: '马上加入',
    StartTime: '开始时间',
    Status: '会议状态',
    Topic: '会议主题',
    Success: '预约成功',
    Url: '会议链接',
    Qrcode: '二维码',
    Detail: '查看详情'
  },
  Logout: '退出登录',
  EditProfile: '修改资料',
  Company: '所属公司',
  Avatar: '头像',
  Email: '邮箱',
  Confirm: '确认',
  Cancel: '取消',
  Save: '保存',
  remark: '备注',
  moreNews: '更多新闻',
  PortfolioNews: '公司新闻',
  BeijingTime: '北京时间',
  siteName: '众为资本',
  home: '首页',
  uploadTypeError: '文件格式不正确!',
  uploadSizeError: '图片大小不能超过 2MB!',
  fileDownload: '文档下载',
  FundOverview: '基金概况',
  LiveOpportunities: '已立项项目',
  PortfolioOpportunities: '可跟投项目机会',
  Number: '编号',
  Code: '项目代号',
  Description: '项目描述',
  Industry: '行业',
  Stage: '投资阶段',
  Action: '操作',
  Early: '早期',
  Growth: '成长期',
  IPO: '上市',
  more: {
    insights: '更多众为观点',
    consumerInsights: '更多研究院洞察',
    events: '更多众为活动',
  },
  download: '下载',
  iosDownload: '苹果用户扫码下载',
  androidDownload: '安卓用户扫码下载',
  consumerDesc: '众为分众消费研究院是众为资本与分众传媒联合组建的数据驱动投资与市场/消费者研究机构。',
  consumerDesc2:
    '研究院整合多种数据源，透过前沿的大数据技术，利用丰富的行业研究经验，深入洞察市场及消费者趋势，挖掘剖析用户体验，从项目发现、投前尽调、投后支持等阶段，为投资团队、股东和被投企业提供敏捷、精准的洞察。研究院汇聚产融资源，对宏观环境、品类发展和不同族群消费趋势进行长期追踪，将市场趋势与消费者洞察相结合，解构新兴品牌增长，把握市场和消费者变化的底层逻辑。',
  latestInsights: '最新洞察',
  MarkedtoMarket: '市值计价',
  PostMoneyValuation: '投后估值',
  ResearchTeam: '研究院团队',
  LatestProgress: '最新进展',
  ViewReport: '查看文档',
  DeadLine: '截止于',
  SelectedProjects: '（部分精选项目）',
  entryRound: '进入轮次',
  LatestValuation: '最新估值',
  ProjectName: '项目名称',
  Date: '日期',
  investmentCurrency: '投资货币',
}

export default cn
