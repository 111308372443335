import axios from "axios";
import router from "@/router";
import { Message } from 'element-ui'

// 创建axios实例
const service = axios.create({
  timeout: 30000 // 请求超时时间
});
// 添加request拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token")
    const lang = localStorage.getItem("locale")
    if (token) {
      config.headers['authorization'] = token
    }
    if (lang && lang == 'en') {
      config.headers['lang'] = 'en_US'
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
// 添加respone拦截器
service.interceptors.response.use(
  response => {
    let res = {};
    res.status = response.status;
    res.data = response.data;
    return res;
  },
  error => {
    if (error.response.status !== 401) {
      Message.error(error.response.data.msg || '请求失败');
      console.log(error.response)
		}
		if (error.response.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
      router.push('/login');
		}
    return Promise.reject(error);
  }
);

export function get(url, data = {}) {
  //默认配置
  let sendObject = {
    url: url,
    method: "get",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    data: data
  };
  return service(sendObject);
}

//封装post请求
export function post(url, data = {}, contentType) {
  //默认配置
  let sendObject = {
    url: url,
    method: "post",
    headers: {
      "Content-Type": contentType ? contentType : "application/json;charset=UTF-8"
    },
    data: data
  };
  return service(sendObject);
}

//封装put方法
export function put(url, data = {}) {
  return service({
    url: url,
    method: "put",
    headers: {
      "Content-Type": "application/json;charset=UTF-8"
    },
    data: JSON.stringify(data)
  });
}

//删除方法
export function deletes(url) {
  return service({
    url: url,
    method: "delete",
    headers: {}
  });
}

export { service };
